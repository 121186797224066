import axios from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class ClientModule extends VuexModule {
  public clients: Array<unknown> = [];

  @Mutation
  public basic(payload: Record<string, string>) {
    this[payload.key] = payload.value;
  }

  @Action
  // eslint-disable-next-line
  public fetch(payload: string) {
    axios
      .get(`data-sources/${payload}.json`)
      // eslint-disable-next-line
      .then((r: any) => {
        if (r.data && r.data.data) {
          this.context.commit("basic", {
            key: payload,
            value: r.data.data,
          });
        }
      })
      // eslint-disable-next-line
      .catch((error: any) => {
        alert(error.message);
      });
  }
}
