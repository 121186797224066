

























































































import { namespace } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";

import ModalBox from "@/components/ModalBox.vue";

const Client = namespace("ClientModule");

@Component({
  components: {
    ModalBox,
  },
})
export default class ClientsTableSample extends Vue {
  @Prop(Boolean)
  private readonly checkable;

  @Prop(Boolean)
  private readonly isEmpty;

  @Prop({ type: Number, default: 10 })
  private readonly perPage;

  private checkedRows: Array<object> = [];
  private isModalActive = false;
  private trashObject: object | null = null;

  get paginated(): boolean {
    return this.clients.length > this.perPage;
  }

  @Client.State
  private clients!: Array<object>;

  trashModalOpen(obj: object): void {
    this.trashObject = obj;
    this.isModalActive = true;
  }

  trashConfirm(): void {
    this.isModalActive = false;

    this.$buefy.snackbar.open({
      message: "Confirmed",
      queue: false,
    });
  }

  trashCancel(): void {
    this.isModalActive = false;
  }
}
