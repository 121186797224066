import { VuexModule, Module, Mutation } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class UserModule extends VuexModule {
  public userName: string | null = null;
  public userEmail: string | null = null;
  public userAvatar: string | null = null;

  @Mutation
  public user(payload: { name: string; email: string; avatar: string }): void {
    if (payload.name) {
      this.userName = payload.name;
    }

    if (payload.email) {
      this.userEmail = payload.email;
    }

    if (payload.avatar) {
      this.userAvatar = payload.avatar;
    }
  }
}
