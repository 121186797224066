






















import { Component, Prop, Vue } from "vue-property-decorator";

import CardComponent from "@/components/CardComponent.vue";
import GrowingNumber from "@/components/GrowingNumber.vue";

@Component({
  components: {
    GrowingNumber,
    CardComponent,
  },
})
export default class CardWidgetComponent extends Vue {
  @Prop({ type: String, default: null })
  private readonly icon;

  @Prop({ type: Number, default: 0 })
  private readonly number;

  @Prop({ type: String, default: null })
  private readonly prefix;

  @Prop({ type: String, default: null })
  private readonly suffix;

  @Prop({ type: String, default: null })
  private readonly label;

  @Prop({ type: String, default: null })
  private readonly type;
}
