




























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ModalBoxComponent extends Vue {
  @Prop(Boolean)
  private readonly isActive;

  @Prop({ type: String, default: null })
  private readonly trashObjectName;

  get isModalActive(): boolean {
    return this.isActive;
  }

  set isModalActive(value: boolean) {
    if (!value) {
      this.cancel();
    }
  }

  @Emit("confirm")
  confirm(): void {
    // console.log("Confirmation");
  }

  @Emit("cancel")
  cancel(): void {
    // console.log("Cancellation");
  }
}
