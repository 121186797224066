


















































































































import { namespace } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

import NavBarMenu from "@/components/NavBarMenu.vue";
import UserAvatar from "@/components/UserAvatar.vue";

const Layout = namespace("LayoutModule");
const User = namespace("UserModule");

@Component({
  components: {
    UserAvatar,
    NavBarMenu,
  },
})
export default class NavBarComponent extends Vue {
  private isMenuActive = false;

  get asideMobileIcon(): string {
    return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
  }

  get menuToggleIcon(): string {
    return this.isMenuActive ? "close" : "dots-vertical";
  }

  @Layout.State
  private isAsideMobileExpanded!: boolean;

  @Layout.State
  private isNavBarVisible!: boolean;

  @User.State
  private userName!: string;

  mounted(): void {
    this.$router.afterEach(() => {
      this.isMenuActive = false;
    });
  }

  asideToggleMobile(): void {
    this.$store.commit("LayoutModule/asideMobileStateToggle");
  }

  asideDesktopOnlyToggle(): void {
    this.$store.dispatch("LayoutModule/asideDesktopOnlyToggle");
  }

  menuToggle(): void {
    this.isMenuActive = !this.isMenuActive;
  }

  logout(): void {
    this.$buefy.snackbar.open({
      message: "Log out clicked",
      queue: false,
    });
  }
}
