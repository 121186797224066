





























import { namespace } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";

import AsideMenuList from "@/components/AsideMenuList.vue";

const Layout = namespace("LayoutModule");

@Component({
  components: {
    AsideMenuList,
  },
})
export default class AsideMenuComponent extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly menu;

  @Layout.State
  public isAsideVisible!: boolean;

  mounted(): void {
    this.$router.afterEach(() => {
      this.$store.dispatch("LayoutModule/asideDesktopOnlyToggle", false);
    });
  }

  asideToggleDesktopOnly() {
    this.$store.dispatch("LayoutModule/asideDesktopOnlyToggle");
  }

  menuClick() {
    // console.log("menu-click");
  }
}
