





































































import { Component, Vue } from "vue-property-decorator";

import * as chartConfig from "@/components/Charts/chart.config";

import TitleBar from "@/components/TitleBar.vue";
import HeroBar from "@/components/HeroBar.vue";
import Tiles from "@/components/Tiles.vue";
import CardWidget from "@/components/CardWidget.vue";
import CardComponent from "@/components/CardComponent.vue";
import LineChart from "@/components/Charts/LineChart.vue";
import ClientsTableSample from "@/components/ClientsTableSample.vue";
import Notification from "@/components/Notification.vue";

@Component({
  components: {
    ClientsTableSample,
    LineChart,
    CardComponent,
    CardWidget,
    Tiles,
    HeroBar,
    TitleBar,
    Notification,
  },
})
export default class HomeView extends Vue {
  titleStack: Array<string> = ["Admin", "Dashboard"];
  chartOptions = chartConfig.chartOptionsMain;
  chartData: unknown;

  created() {
    this.fillChartData();

    this.$buefy.snackbar.open({
      message: "Welcome back",
      queue: false,
    });
  }

  fillChartData() {
    this.chartData = chartConfig.sampleChartData();
  }
}
