





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class HeroBarComponent extends Vue {
  @Prop({ type: Boolean, default: true })
  private readonly hasRightVisible;
}
