import Vue from "vue";
import Vuex from "vuex";

import LayoutModule from "@/store/modules/layout";
import ClientModule from "@/store/modules/client";
import UserModule from "@/store/modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    LayoutModule,
    ClientModule,
    UserModule,
  },
});
