















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class NavBarMenuComponent extends Vue {
  @Prop(Boolean)
  private readonly isHoverable;

  private isDropdownActive = false;

  get dropdownIcon(): string {
    return this.isDropdownActive ? "chevron-up" : "chevron-down";
  }

  mounted(): void {
    window.addEventListener("click", this.forceClose);
  }

  beforeDestroy(): void {
    window.removeEventListener("click", this.forceClose);
  }

  toggle(): void {
    if (!this.isHoverable) {
      this.isDropdownActive = !this.isDropdownActive;
    }
  }

  // eslint-disable-next-line
  forceClose(e: any): void {
    if (
      this.$refs &&
      this.$refs.root &&
      // eslint-disable-next-line
      !(this.$refs.root as any).contains(e.target)
    ) {
      this.isDropdownActive = false;
    }
  }
}
