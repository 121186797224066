









import menu from "@/menu";
import NavBar from "@/components/NavBar.vue";
import AsideMenu from "@/components/AsideMenu.vue";
import FooterBar from "@/components/FooterBar.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    NavBar,
    AsideMenu,
    FooterBar,
  },
})
export default class AppBaseView extends Vue {
  menu: Array<unknown> = menu;

  created() {
    this.$store.commit("UserModule/user", {
      name: "John Doe",
      email: "john@example.com",
      avatar: "https://avatars.dicebear.com/v2/gridy/John-Doe.svg",
    });
  }
}
