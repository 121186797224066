
























import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class NotificationComponent extends Vue {
  private isDismissed = false;

  get hasRightSlot(): unknown {
    return this.$slots.right;
  }

  dismiss(): void {
    this.isDismissed = true;

    this.$buefy.snackbar.open({
      message: "Dismissed",
      queue: false,
    });
  }
}
