































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Layout = namespace("LayoutModule");

@Component({})
export default class FooterBarComponent extends Vue {
  private year: number = new Date().getFullYear();

  @Layout.State
  private isFooterBarVisible!: boolean;
}
