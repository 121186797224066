






























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TitleBarComponent extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly titleStack;
}
