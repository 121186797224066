




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import numeral from "numeral";

@Component({})
export default class GrowingNumberComponent extends Vue {
  @Prop({ type: String, default: null })
  private readonly prefix;

  @Prop({ type: String, default: null })
  private readonly suffix;

  @Prop({ type: Number, default: 0 })
  private readonly value;

  @Prop({ type: Number, default: 500 })
  private readonly duration;

  private newValue = 0;
  get newValueFormatted(): number {
    return this.newValue < 1000
      ? this.newValue
      : numeral(this.newValue).format("0,0");
  }

  @Watch("value")
  valueChanged(): void {
    this.growInit();
  }

  mounted() {
    this.growInit();
  }

  grow(m: number): void {
    const v = Math.ceil(this.newValue + m);

    if (v > this.value) {
      this.newValue = this.value;
      return;
    }

    this.newValue = v;

    setTimeout(() => {
      this.grow(m);
    }, 25);
  }

  growInit(): void {
    this.grow(this.value / (this.duration / 25));
  }
}
