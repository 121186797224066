import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class LayoutModule extends VuexModule {
  /* NavBar */
  public isNavBarVisible = true;

  /* FooterBar */
  public isFooterBarVisible = true;

  /* Aside */
  public isAsideVisible = true;
  public isAsideMobileExpanded = true;

  /* Aside */
  @Mutation
  asideMobileStateToggle(payload: boolean | null = null) {
    const htmlClassName = "has-aside-mobile-expanded";

    let isShow: boolean;

    if (payload !== null) {
      isShow = payload;
    } else {
      isShow = !this.isAsideMobileExpanded;
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName);
    } else {
      document.documentElement.classList.remove(htmlClassName);
    }

    this.state = {
      ...this.state,
      isAsideMobileExpanded: isShow,
    };
  }

  /* Full Page mode */
  @Mutation
  fullPage(payload: Record<string, unknown>) {
    this.isNavBarVisible = !payload;
    this.isAsideVisible = !payload;
    this.isFooterBarVisible = !payload;
  }

  @Action
  asideDesktopOnlyToggle(payload: unknown = null) {
    let method: string;

    switch (payload) {
      case true:
        method = "add";
        break;
      case false:
        method = "remove";
        break;
      default:
        method = "toggle";
    }
    document.documentElement.classList[method](
      "has-aside-desktop-only-visible"
    );
  }

  @Action
  // eslint-disable-next-line
  toggleFullPage(payload: any) {
    this.context.commit("fullPage", payload);

    document.documentElement.classList[!payload ? "add" : "remove"](
      "has-aside-left",
      "has-navbar-fixed-top"
    );
  }
}
