
import { Component, Prop, Vue } from "vue-property-decorator";
import chunk from "lodash/chunk";

@Component({})
export default class TilesComponent extends Vue {
  @Prop({ type: Number, default: 5 })
  private readonly maxPerRow;

  render(createElement) {
    const renderAncestor = (elements) =>
      createElement(
        "div",
        { attrs: { class: "tile is-ancestor" } },
        elements.map((element) => {
          return createElement("div", { attrs: { class: "tile is-parent" } }, [
            element,
          ]);
        })
      );

    if (
      this.$slots &&
      this.$slots.default &&
      this.$slots.default.length <= this.maxPerRow
    ) {
      return renderAncestor(this.$slots.default);
    } else {
      return createElement(
        "div",
        { attrs: { class: "is-tiles-wrapper" } },
        chunk(this.$slots.default, this.maxPerRow).map((group) => {
          return renderAncestor(group);
        })
      );
    }
  }
}
