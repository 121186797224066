























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class CardComponent extends Vue {
  @Prop({ type: String, default: null })
  private readonly title;

  @Prop({ type: String, default: null })
  private readonly icon;

  @Prop({ type: String, default: null })
  private readonly headerIcon;

  @Emit("header-icon-click")
  headerIconClick() {
    //
  }
}
