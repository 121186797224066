






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class UserClassAvatarComponent extends Vue {
  @Prop({ type: String, default: null })
  private readonly avatar;

  get newAvatar(): string {
    return this.avatar ? this.avatar : this.$store.state.UserModule.userAvatar;
  }
}
