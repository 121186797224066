export default [
  "General",
  [
    {
      to: "/",
      icon: "desktop-mac",
      label: "Dashboard",
    },
  ],
  "Examples",
  [
    {
      to: "/tables",
      label: "Tables",
      icon: "table",
      updateMark: true,
    },
    {
      to: "/forms",
      label: "Forms",
      icon: "square-edit-outline",
    },
    {
      to: "/profile",
      label: "Profile",
      icon: "account-circle",
    },
    {
      to: "/login",
      label: "Login",
      icon: "lock",
    },
    {
      label: "Submenus",
      subLabel: "Submenus Example",
      icon: "view-list",
      menu: [
        {
          href: "#void",
          label: "Sub-item One",
        },
        {
          href: "#void",
          label: "Sub-item Two",
        },
      ],
    },
  ],
  "About",
  [
    {
      href: "https://lab.alexistourneux.fr/demos/vuejs-typescript-dashboard-demo",
      label: "Project Repository",
      icon: "gitlab",
      target: "_blank",
    },
    {
      href: "https://github.com/AlexTorx",
      label: "My GitHub",
      icon: "github-circle",
      target: "_blank",
    },
    {
      href: "https://www.linkedin.com/in/alexis-tourneux/",
      label: "My LinkedIn",
      icon: "linkedin",
      target: "_blank",
    },
  ],
];
